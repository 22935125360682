import './style.css';
import React, { useEffect, useMemo, useState } from 'react';
import CompanyArchiveService from '../../../../../../network/CompanyArchiveService';
//import { formatDate } from '../../../../../../utils';
import { Table } from '../../../../components/table';
import { TableColumn } from '../../../../components/table/props';
import { formatDate } from '../../../../../../utils';
import { useQuery } from 'react-query';
import { useSuperior } from '../../../../../../components/superior-context';
import Input from '../../../../components/input';
import Translation from '../../../../../../components/translation';
import { useTranslation } from '../../../../../../components/translation-context/TranslationContext';
import BusyIndicator from '../../../../../../components/busy-indicator';
import CompanyReportingService from '../../../../../../network/CompanyReportingService';
import { utcToZonedTime, format } from 'date-fns-tz';
import { addMonths, startOfDay, endOfDay } from 'date-fns';
import loading from './assets/loading.json';
import Lottie from 'lottie-react-web';

const Reports = () => {
	// Attributes
	const { superior } = useSuperior();
	const { translate } = useTranslation();
	const timeZone = 'Europe/Berlin';
	const [startDate, setStartDate] = useState<any>(
		utcToZonedTime(addMonths(startOfDay(new Date()), -1), timeZone)
	);
	const [endDate, setEndDate] = useState<any>(
		utcToZonedTime(endOfDay(new Date()), timeZone)
	);
	const query = useQuery(
		`company-${superior?.companyId ?? ''}-archives`,
		refreshCompanyArchives,
		{ refetchOnWindowFocus: false }
	);
	const [archivesAreLoading, setArchivesAreLoading] = useState(false);
	const { data: reportingOptions } = useQuery(
		`company-${superior?.companyId ?? ''}-reportings-1`,
		() => CompanyReportingService.get(superior?.companyId ?? '', 1),
		{ enabled: !!superior?.companyId }
	);

	const cols = useMemo<Array<TableColumn<any>>>(
		() => [
			{
				Header: translate('status'),
				accessor: () => translate('finished'),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Status' && elt.selected
				),
			},
			{
				Header: translate('superior'),
				accessor: (check: any) =>
					`${check.request.superior.firstName} ${check.request.superior.lastName}`,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Superior' && elt.selected
				),
			},
			{
				Header: translate('superiorEmail'),
				accessor: (check: any) => check.request.superior.email,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Superior' && elt.selected
				),
			},
			{
				Header: translate('requestDate'),
				accessor: (check: any) => formatDate(check.request.creationDate),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Request Date' && elt.selected
				),
			},
			{
				Header: translate('workerFullName'),
				accessor: (check: any) => check.request.worker.fullName,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Worker' && elt.selected
				),
			},
			{
				Header: translate('matricule'),
				accessor: (check) => check.request.workerExternalId,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Worker ID' && elt.selected
				),
			},
			{
				Header: translate('workerNISS'),
				accessor: (check) => check.request.workerNationalNumber,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Worker NISS' && elt.selected
				),
			},
			{
				Header: translate('address'),
				accessor: (check) => check.request.worker.address,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Address' && elt.selected
				),
			},
			{
				Header: translate('phoneNumber'),
				accessor: (check) => check.request.worker.phoneNumber,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Phone Number' && elt.selected
				),
			},
			{
				Header: translate('incapacityStartDate'),
				accessor: (check) =>
					format(new Date(check.request.startDate), 'dd/MM/yyyy'),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Incapacity Start' && elt.selected
				),
			},
			{
				Header: translate('incapacityEndDate'),
				accessor: (check) =>
					check.request.endDate &&
					format(new Date(check.request.endDate), 'dd/MM/yyyy'),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Incapacity End' && elt.selected
				),
			},
			{
				Header: translate('newIncapacityEndDate'),
				accessor: (check: any) =>
					`${
						check.newIncapacityEndDate
							? format(new Date(check.newIncapacityEndDate), 'dd/MM/yyyy HH:mm')
							: '-'
					}`,
				selected: reportingOptions?.data?.find(
					(elt: any) =>
						elt.option.name == 'New Incapacity End Date' && elt.selected
				),
			},
			{
				Header: translate('incapacityType'),
				accessor: (check: any) => translate(`${check.request.incapacityType}`),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Certificate Type' && elt.selected
				),
			},
			{
				Header: translate('certificateType'),
				accessor: (check: any) => translate(`${check.request.certificateType}`),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Incapacity Type' && elt.selected
				),
			},
			{
				Header: translate('random'),
				accessor: (check) => '' + check.isRandom,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Random' && elt.selected
				),
			},
			{
				Header: translate('location'),
				accessor: (check: any) =>
					translate(check.request.location.toLowerCase()),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Check Location' && elt.selected
				),
			},
			{
				Header: translate('timing'),
				accessor: (check: any) => translate(check.request.timing.toLowerCase()),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Check Timing' && elt.selected
				),
			},
			{
				Header: translate('checkDate'),
				accessor: (check: any) => formatDate(check.checkDate),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Check Date' && elt.selected
				),
			},
			{
				Header: translate('checkOccurence'),
				accessor: (check: any) =>
					translate(`CheckOccurence${check.checkOccurence}`),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Check Occurance' && elt.selected
				),
			},
			{
				Header: translate('earlyReturn'),
				accessor: (check: any) => translate(`${getEarlyReturnStatus(check)}`),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Early Return' && elt.selected
				),
			},
			{
				Header: translate('distance'),
				accessor: (check: any) => {
					if (check.distanceToCheck == 0) {
						return `-`;
					}

					if (check.distanceToCheck !== undefined) {
						return `${check.distanceToCheck.toFixed(2)}km`;
					}

					return `${check.distanceToCheck}km`;
				},
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Distance' && elt.selected
				),
			},
			{
				Header: translate('price'),
				accessor: (check: any) => {
					if (check.companyTotalPrice == 0) {
						return `-`;
					}

					return `${check.companyTotalPrice?.toFixed(2)} EUR`;
				},
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Price' && elt.selected
				),
			},
			{
				Header: translate('externalId'),
				accessor: (check: any) => check.request.uniqueKey,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'External ID' && elt.selected
				),
			},
			{
				Header: translate('subEmployer'),
				accessor: (check: any) => check.request.subEmployer,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Sub Employer' && elt.selected
				),
			},
			{
				Header: translate('prolongationNeeded'),
				accessor: (check: any) => translate(check.request.plausibility.toLowerCase()),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Prolongation Needed' && elt.selected
				),
			},
		],
		[reportingOptions]
	);
	const columns = cols.filter((elt: any) => elt.selected);

	// Effects
	useEffect(() => {
		query.refetch();
	}, [startDate, endDate]);

	// Functions
	function handleStartDateChanged(event: any) {
		setStartDate(event.target.value);
	}

	function handleEndDateChanged(event: any) {
		setEndDate(event.target.value);
	}

	async function refreshCompanyArchives() {
		const startDateFormattedString =
			format(new Date(startDate), "yyyy-MM-dd'T'HH:mm:ss") + 'Z';
		const endDateFormattedString =
			format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss") + 'Z';

		setArchivesAreLoading(true);
		const arc = (
			await CompanyArchiveService.get(
				superior?.companyId ?? '',
				startDateFormattedString,
				endDateFormattedString
			)
		).data;
		setArchivesAreLoading(false);
		return arc;
	}

	function getEarlyReturnStatus(check: any) {
		if (
			check.checkOccurence === 6 ||
			(check.checkOccurence >= 3 &&
				new Date(check.request.endDate).getDate() ==
					new Date(check.newIncapacityEndDate).getDate())
		) {
			return 'earlyReturnFalse';
		}
		if (
			check.checkOccurence === 7 ||
			(check.checkOccurence >= 3 &&
				new Date(check.request.endDate).getDate() !=
					new Date(check.newIncapacityEndDate).getDate())
		) {
			return 'earlyReturnTrue';
		}

		return '-';
	}

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: loading,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div className='reports-container'>
			<div className='reports-header-container'>
				<h1>
					<Translation>reports</Translation>
				</h1>
				<div className='reports-datepicker'>
					<span
						style={{
							color: '#405364',
							fontSize: 13,
							fontFamily: 'Asap, sans-sérif',
						}}
					>
						<Translation>selectRangeOfDates</Translation>
					</span>
					<Input
						placeholder={undefined}
						noError
						value={startDate}
						onChange={handleStartDateChanged}
						type='date'
						name='startDate'
						style={{ textAlign: 'center', cursor: 'pointer' }}
					/>
					<div style={{ marginLeft: 7.5, marginRight: 5 }}>{' - '}</div>
					<Input
						placeholder={undefined}
						noError
						value={endDate}
						onChange={handleEndDateChanged}
						type='date'
						name='endDate'
						style={{ textAlign: 'center', cursor: 'pointer' }}
					/>
				</div>
			</div>

			{/* ARCHIVES TABLE */}
			<BusyIndicator query={query}>
				{archivesAreLoading ? (
					<Lottie options={defaultOptions} height={200} width={200} />
				) : (
					<Table
						tableName='reports'
						rows={query.data ?? []}
						columns={columns}
						isExportable
					/>
				)}
			</BusyIndicator>
		</div>
	);
};

export default Reports;
