import './style.css';
import React, { useEffect, useMemo, useState } from 'react';
//import { formatDate } from '../../../../../../utils';
import { Table } from '../../../../components/table';
import { TableColumn } from '../../../../components/table/props';
import { useQuery, useQueryClient } from 'react-query';
import CompanyCertificateService from '../../../../../../network/CompanyCertificateService';
import { formatDate, formatDateAndTime } from '../../../../../../utils';
import { useSuperior } from '../../../../../../components/superior-context';
import Input from '../../../../components/input';
import Translation from '../../../../../../components/translation';
import { useTranslation } from '../../../../../../components/translation-context/TranslationContext';
import { motion } from 'framer-motion';
import BusyIndicator from '../../../../../../components/busy-indicator';
import { utcToZonedTime } from 'date-fns-tz';
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';
import CompanyReportingService from '../../../../../../network/CompanyReportingService';

const Certificates = () => {
	// Attributes
	const { superior } = useSuperior();
	const { translate } = useTranslation();
	const timeZone = 'Europe/Berlin';
	const [startDate, setStartDate] = useState<any>(
		utcToZonedTime(startOfMonth(startOfDay(new Date())), timeZone)
	);
	const [endDate, setEndDate] = useState<any>(
		utcToZonedTime(endOfDay(new Date()), timeZone)
	);

	// Queries & Mutations
	const query = useQuery(
		`company-${superior?.companyId}-certificates`,
		refreshCompanyCertificates, {retry : false, refetchOnWindowFocus: false}
	);
	const { data: reportingOptions } = useQuery(
		`company-${superior?.companyId ?? ''}-reportings-2`,
		() => CompanyReportingService.get(superior?.companyId ?? '', 2),
		{ enabled: !!superior?.companyId }
	);

	const queryClient = useQueryClient();

	const cols = useMemo<Array<TableColumn<any>>>(() => {
		const columns = [
			{
				Header: translate('entryType'),
				accessor: (certificate: any) => translate(certificate.entryType),
				selected: true,
			},
			{
				Header: translate('reception'),
				accessor: (certificate: any) =>
					formatDateAndTime(certificate.creationDate),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Reception' && elt.selected
				),
			},
			{
				Header: translate('matricule'),
				accessor: (certificate: any) => certificate.employee.matricule,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Matricule' && elt.selected
				),
			},
			{
				Header: translate('firstName'),
				accessor: (certificate: any) => certificate.employee.firstName,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'First Name' && elt.selected
				),
			},
			{
				Header: translate('lastName'),
				accessor: (certificate: any) => certificate.employee.lastName,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Last Name' && elt.selected
				),
			},
			{
				Header: translate('superior'),
				accessor: (certificate: any) => certificate.superior,
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Superior' && elt.selected
				),
			},
			{
				Header: translate('incapacityStartDate'),
				accessor: (certificate: any) => formatDate(certificate.startDate),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Incapacity Start' && elt.selected
				),
			},
			{
				Header: translate('incapacityEndDate'),
				accessor: (certificate: any) => formatDate(certificate.endDate),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Incapacity End' && elt.selected
				),
			},
			{
				Header: translate('incapacityType'),
				accessor: (certificate: any) =>
					translate(
						`raison${certificate.incapacityReason
							.substr(0, 1)
							.toUpperCase()}${certificate.incapacityReason?.substring(1)}`
					),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Certificate Type' && elt.selected
				),
			},
			{
				Header: translate('certificateType'),
				accessor: (certificate: any) =>
					certificate.entryType === 'ABSENCE_DECLARATION'
						? '-'
						: translate(`type${certificate.certifType}`),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Incapacity Type' && elt.selected
				),
			},
			{
				Header: translate('authorizedLeave'),
				accessor: (certificate: any) =>
					certificate.entryType === 'ABSENCE_DECLARATION'
						? '-'
						: translate(`${certificate.authorizedExit}`),
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Authorized Leave' && elt.selected
				),
			},
			{
				Header: translate('certificateCopy'),
				accessor: (certificate: any) => {
					if (
						certificate.entryType === 'ABSENCE_DECLARATION'
					)
						return '-';

					return (
						<motion.a
							whileHover={{ scale: 1.025 }}
							href={certificate.urlCertificate}
							target='_blank'
						>
							<Translation>clickHere</Translation>
						</motion.a>
					);
				},
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Certificate Link' && elt.selected
				),
			},
			{
				Header: translate('medicalCheckLaunched'),
				accessor: (certificate: any) => {
					if (certificate.isCheckRequestLaunched) {
						return translate('aCheckHasBeenLaunched');
					}

					return (
						<motion.a
							whileHover={{ scale: 1.025 }}
							href={`https://${certificate.employee.company.key}.mycertificate.be/employee/${certificate.employee.id}/certificate/${certificate.id}`}
							target='_blank'
							style={{
								background: '#F27868',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '7.5px 10px',
								color: 'white',
								borderRadius: 8,
								fontWeight: 400,
								textAlign: 'center',
								cursor: 'pointer',
								textDecoration: 'none',
							}}
						>
							<Translation>launchACheck</Translation>
						</motion.a>
					);
				},
				selected: reportingOptions?.data?.find(
					(elt: any) => elt.option.name == 'Launch Check' && elt.selected
				),
			},
		];

		return columns;
	}, [reportingOptions?.data]);

	// Effects
	useEffect(() => {
		queryClient.removeQueries(`company-${superior?.companyId}-certificates`);
		query.refetch();
	}, [startDate, endDate]);

	// Functions
	function handleStartDateChanged(event: any) {
		setStartDate(
			utcToZonedTime(startOfDay(new Date(event.target.value)), timeZone)
		);
	}

	function handleEndDateChanged(event: any) {
		setEndDate(
			utcToZonedTime(endOfDay(new Date(event.target.value)), timeZone)
		);
	}

	async function refreshCompanyCertificates() {
		const arc = (
			await CompanyCertificateService.get(
				superior?.companyId ?? '',
				startDate,
				endDate
			)
		).data;
		return arc;
	}

	return (
		<div className='certificates-container'>
			<div className='certificates-header-container'>
				<h1>
					<Translation>certificates</Translation>
				</h1>

				<div className='certificates-datepicker'>
					<span
						style={{
							color: '#405364',
							fontSize: 13,
							fontFamily: 'Asap, sans-sérif',
						}}
					>
						<Translation>selectRangeOfDates</Translation>
					</span>
					<Input
						noError
						value={startDate}
						onChange={handleStartDateChanged}
						type='date'
						name='startDate'
						style={{ textAlign: 'center', cursor: 'pointer' }}
					/>
					<div style={{ marginLeft: 7.5, marginRight: 5 }}>{' - '}</div>
					<Input
						noError
						value={endDate}
						onChange={handleEndDateChanged}
						type='date'
						name='endDate'
						style={{ textAlign: 'center', cursor: 'pointer' }}
					/>
				</div>
			</div>
			<BusyIndicator query={query}>
				<Table
					tableName='Certificates'
					rows={query.data ?? []}
					columns={cols.filter((elt: any) => elt.selected)}
					isExportable
					exportButtonColor={'#EB526D'}
				/>
			</BusyIndicator>
		</div>
	);
};

export default Certificates;
