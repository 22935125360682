import React, { ChangeEvent, useMemo, useState } from "react";
import { InputProps } from './props';
import { isNullOrEmpty } from '../../../../utils';
import './style.css';
import ReactDatePicker from "react-datepicker";
import Translation from "../../../../components/translation";
import { useTranslation } from "../../../../components/translation-context/TranslationContext";
import ReactInputMask from "react-input-mask";
import CustomInputDate from "./input-date";
import { isValid, parse } from "date-fns";

const Input = (props: InputProps) => {
    const { translate } = useTranslation();
    const { minDate, name, error, noErrorLabel, component, className = "", type, onChange, value, ...rest } = props;
    let handleChange = onChange as any;

    // Functions
    const computedClassName = useMemo(() => {
      let base = `input ${className}`;

      if (props.error) {
          base += " error"
      }

      return base;
  }, [error])

    // Attributes
    const ExampleCustomInput = ({ value, onClick }: any) => {
      const [tempValue, setTempValue] = useState(value);

      const handleTempDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const dateValue = e.target.value;
        setTempValue(dateValue);
      };

      //Function to allow user to write a date on blur and save it in the state tempValue to verify if the date is valid
      const handleBlur = () => {
        if (tempValue === "") {
          handleChange({ target: { name: name, value: null, type: "date" } });
        } else {
          const parsedDate = parse(tempValue, "dd/MM/yyyy", new Date());
          if (
            isValid(parsedDate) &&
            (!minDate || minDate.getDate() <= parsedDate.getDate())
          ) {
            handleChange({
              target: { name: name, value: parsedDate, type: "date" },
            });
          } else {
            setTempValue(value);
          }
        }
      };
      
      return (
            <CustomInputDate 
                handleBlur={handleBlur} 
                handleTempDateChange={handleTempDateChange} 
                tempValue={tempValue} 
                onClickDatePicker={onClick} 
                computedClassName={computedClassName} 
                value={props.value} 
                error={error} 
                noErrorLabel={noErrorLabel} 
                noError={props.noError} 
                {...rest} 
            />
    )};

    if (type === "date") {
        return (
            <div className="input-container">
                <ReactDatePicker
                    minDate={minDate}
                    name={name}
                    customInput={<ExampleCustomInput noError />}
                    className="input-container"
                    dateFormat="dd/MM/yyyy"
                    isClearable={props.isClearable}
                    popperModifiers={{
                        preventOverflow: {
                            enabled: true
                        }
                    }}
                    selected={value ? value as any : undefined}
                    onChange={(date: any) => handleChange({ target: { name: name, value: date, type: 'date' } })}
                />
            </div>
        )
    }

    function renderInput() {
        if (props.mask) {
            return (
                <ReactInputMask onChange={props.onChange} value={props.value} mask={props.mask}>
                    {
                        () => (
                            <input {...props} placeholder={translate(props.placeholder ?? "")} className={computedClassName} />
                        )
                    }
                </ReactInputMask>
            )
        }

        return (
            <input {...props} placeholder={translate(props.placeholder ?? "")} className={computedClassName} />
        )
    }

    if (component) {
        return (
            <div className="input-container">
                <p className="input-title" style={{ opacity: 1 }}>
                    {props.placeholder}
                </p>
                <props.component {...props} className={computedClassName} />
                <p className="input-error" style={{ opacity: 0 }}>
                    <Translation>
                        requiredField
                    </Translation>
                </p>
            </div>
        );
    }

    return (
        <div className="input-container">
            <p className="input-title" style={{ opacity: isNullOrEmpty(props.value?.toString() || "") ? 0 : 1}}>
                <Translation>
                    {props.placeholder}
                </Translation>
            </p>

            {renderInput()}

            <p className="input-error" style={{ opacity: (error && !noErrorLabel) ? 1 : 0 }}>
                <Translation>
                    requiredField
                </Translation>
            </p>
        </div>
    );
}

export default Input;