import React, { useEffect, useMemo } from "react";
import { useGlobalFilter, usePagination, useTable } from 'react-table';
import Button from "../../../../components/button";
import Translation from "../../../../components/translation";
import { downloadCSV } from "../../../../utils";
import { Raised } from '../raised';
import { SearchInput } from '../search-input';
import { TableProps } from './props';
import "./style.css";

const SHOWN_PAGES = 3;

export function Table<T extends object = {}>(props: TableProps<T>) {

	// Attributes
	const { columns, rows, pageSize = 10, tableName, isExportable, exportButtonColor } = props;
	const {
		page,
		prepareRow,
		headerGroups,
		getTableProps,
		getTableBodyProps,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setGlobalFilter,
		globalFilteredRows,
		rows: allRows,
		state
	} = useTable<T>(
		{
			columns,
			data: rows,
			initialState: { pageSize }
		},
		useGlobalFilter,
		usePagination,
	);

	// Effects
	useEffect(() => {
		if (pageSize === state.pageSize) {
			return;
		}

		setPageSize(pageSize);
	}, [pageSize]);

	// Memos
	const displayedPageNumbers = useMemo<Array<number>>(() => {
		const numbers = (() => {
			if (pageCount === 0) {
				return [0];
			} else if (pageCount <= SHOWN_PAGES) {
				return pageOptions.slice(0, SHOWN_PAGES);
			} else if (state.pageIndex <= pageCount - SHOWN_PAGES) {
				return pageOptions.slice(state.pageIndex, state.pageIndex + SHOWN_PAGES);
			}

			return pageOptions.slice(-SHOWN_PAGES);
		})();

		return numbers.map(p => p + 1);
	}, [state.pageIndex, pageCount]);

	// Functions
	function exportCSV() {

		const csv = [
			columns.map(c => c.Header?.toString()).join(";"),
			...allRows.map(row => {
				prepareRow(row);
				let csvRow = row.cells.map(elt => elt.value).join(';');
				csvRow = csvRow.replace("[object Object]", "-");
				return csvRow.replace("[object Object]", "-");
			})
			// .map((value: any) => value).join(";"))
		]
			.join("\n");


		downloadCSV(csv, tableName);
	}

	return (
		<Raised className="table-container">
			<div className="table-header">
				<SearchInput onChange={e => setGlobalFilter(e.target.value)} />
				{isExportable && (
					<Button type={exportButtonColor ? "border-red" : "border"} onClick={exportCSV} style={{ fontSize: 12, padding: '10px 15px', height: 'fit-content' }}>
						ExportCSV
					</Button>
				)}
			</div>

			<div className="table-wrapper">
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render("Header")}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell, index) => (
										<td {...cell.getCellProps({ className: columns[index].separator ? "table-separator" : undefined })}>
											{cell.render("Cell")}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<div className="table-pagination">
				<span className="table-pages">
					{(state.pageSize * state.pageIndex) + page.length}<Translation>outOf</Translation>{globalFilteredRows.length}<Translation>items</Translation>
				</span>

				<div className="table-page-numbers">
					<img
						role="button"
						className="pagination double"
						onClick={() => gotoPage(0)}
						aria-disabled={!canPreviousPage}
						src={require("../../../../assets/double_arrow.png")}
					/>

					<img
						role="button"
						className="pagination single"
						onClick={() => previousPage()}
						aria-disabled={!canPreviousPage}
						src={require("../../../../assets/arrow.png")}
					/>

					{displayedPageNumbers.map(p => (
						<span onClick={() => gotoPage(p - 1)} className={`page-number ${p === state.pageIndex + 1 ? "selected" : ""}`}>
							{p}
						</span>
					))}

					<img
						role="button"
						onClick={() => nextPage()}
						className="pagination single"
						aria-disabled={!canNextPage}
						src={require("../../../../assets/arrow-right.png")}
					/>

					<img
						role="button"
						className="pagination double"
						aria-disabled={!canPreviousPage}
						onClick={() => gotoPage(pageCount - 1)}
						src={require("../../../../assets/double_arrow-right.png")}
					/>
				</div>
			</div>
		</Raised>
	);
}