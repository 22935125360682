export function isNullOrEmpty(element: string) {
    return !element || element.trim().length === 0;
}

export function formatDate(date: string) {
    if(date && !date.includes('0001'))
    {
        let newDate = new Date(date);

        let year = newDate.getFullYear();
        
        let month = (newDate.getMonth())+1;
        let formattedMonth = ""+month;
        if(month < 10)
        {
            formattedMonth = "0"+month;
        }

        let day = newDate.getDate();
        let formattedDay = ""+day;
        if(day < 10)
        {
            formattedDay = "0"+day;
        }

        return `${formattedDay}/${formattedMonth}/${year}`;
    }

    return "-";
}

export function formatDateAndTime(date: string) {
    if(date)
    {
        let newDate = new Date(date);

        let year = newDate.getFullYear();
        
        let month = (newDate.getMonth())+1;
        let formattedMonth = ""+month;
        if(month < 10)
        {
            formattedMonth = "0"+month;
        }

        let day = newDate.getDate();
        let formattedDay = ""+day;
        if(day < 10)
        {
            formattedDay = "0"+day;
        }

        let hours = newDate.getHours();
        let formattedHours = hours+"";
        if(hours < 10) {
            formattedHours = "0" + hours;
        }

        let minutes = newDate.getMinutes();
        let formattedMinutes = minutes+"";
        if(minutes < 10) {
            formattedMinutes = "0" + minutes;
        }

        let seconds = newDate.getSeconds();
        let formattedSeconds = seconds+"";
        if(seconds < 10) {
            formattedSeconds = "0" + seconds;
        }

        return `${formattedDay}/${formattedMonth}/${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    return '';
}

export function downloadCSV(csv: string, fileName: string)
{
    fileName = `Export-${fileName}-Medicheck-${new Date().toLocaleDateString()}`;
	let blob = new Blob([`\uFEFF${csv}`], { type: "text/csv;charset=utf-8;" });

	const link = document.createElement("a");
	link.download = `${fileName}.csv`;
	link.href = `${URL.createObjectURL(blob)}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}