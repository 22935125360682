import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import Error from "./modules/error";
import { Switch, Route, Redirect, BrowserRouter } from "react-router-dom";
import MyInterface from "./modules/my-interface";
import { QueryClient, QueryClientProvider } from "react-query";
import { withTranslation } from "./components/translation-context/TranslationContextProvider";
import { StripeProvider } from "react-stripe-elements";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";

const App = () => {
	// Attributes
	// @ts-ignore
	const isIE = /*@cc_on!@*/ false || !!document.documentMode;
	const queryClient = new QueryClient();

	if (isIE) {
		return (
			<div>
				Medicheck doesn't support Internet Explorer.
				<br />
				Please install Edge, Safari, Mozilla Firefox, Opera or Google Chrome.
			</div>
		);
	}

	return (
		<BrowserRouter>
			<StripeProvider apiKey="pk_live_hzncDKtkTyx2dwJRoZdlie9M001r2mEmDS">
				<QueryClientProvider client={queryClient}>
					<Switch>
						<Route path="/upload" render={() => <Upload />} />
						<Route path="/error" render={() => <Error />} />
						<Route path="/:language" render={() => withTranslation(<MyInterface />)} />
						<Route path="/" render={() => <Redirect to={`/en`} />} />
					</Switch>
				</QueryClientProvider>
			</StripeProvider>
			<ToastContainer />
		</BrowserRouter>
	);
};

export default App;

const Upload = () => {
	const auth = getAuth();
	function handleChange(fileInput: any) {
		const table: any[] = [];
		const goodTable: any[] = [];
		var reader = new FileReader();
		reader.onload = async function () {
			const res: string = reader.result as string;
			res.split("\n")?.forEach((x) => table.push(x.split(";")[1]));
			for (let i = 0; i < table.length; i++) {
				let user: any;
				try {
					user = await createUserWithEmailAndPassword(auth, table[i], "MedicheckPassw0rdImpossibleToGuessN1ghtB0rnB0rnB0rn");
					goodTable.push({ email: table[i], uuid: user.user.uid });
				} catch {
					console.log(table[i]);
				}
			}

			console.log(goodTable);
		};
		// start reading the file. When it is done, calls the onload event defined above.
		reader.readAsText(fileInput.files[0]);
	}

	return <input type="file" onChange={(e) => handleChange(e.target)} />;
};
